<div class="dialog-content-wrapper admin_modal global-modal prsn_dialog">
  <button class="closeModal" (click)="closeModal()"><img src="../../assets/icons/close_mpdal_icon.svg"></button>
  <div class="modal_section">

    <h2>{{modalTitle}}</h2>

<!-- Compliance  Category -->
<div *ngIf="action == 'AddCompliancecategory' || action == 'EditCompliancecategory'">
  <form class="row" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
    <div class="col-sm-12">
      <div style="margin-bottom: 20px;">
      <mat-radio-group class="mb-2" [(ngModel)]="InputData.ISGoverment" name="ISGoverment" required>
        <mat-radio-button [value]="true">Government</mat-radio-button>
        <mat-radio-button class="ml-3" [value]="false">Non Government</mat-radio-button>
      </mat-radio-group>
      </div>


      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <input matInput type="text" [(ngModel)]="InputData.ComplianceCategoryName"
          #ComplianceCategoryName="ngModel" name="ComplianceCategoryName" class="form-control" required>
      </mat-form-field>
    </div>
    <div class="action-btn">
      <button type="submit" class="btn btn-primary" [disabled]="myForm.form.invalid"
        (click)="dialogRef.close(InputData)">
        {{InputData.ComplianceCategoryId ? 'Update' : 'Save'}}
      </button>
      <button type="button" class="btn btn-secondary" (click)="dialogRef.close(false)">
        Cancel
      </button>
    </div>
  </form>
</div>


    <!-- Compliance Sub Category -->
    <div *ngIf="action == 'AddComplianceSubcategory' || action == 'EditComplianceSubcategory'">
      <form class="row" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class="col-sm-12">

          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <mat-select [(ngModel)]="InputData.ComplianceCategoryId" required name="ComplianceCategoryId">
              <mat-option [value]="list.ComplianceCategoryId" *ngFor="let list of categorylist">
                {{list.ComplianceCategoryName}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Sub Category Name</mat-label>
            <input matInput type="text" required [(ngModel)]="InputData.ComplianceSubCategoryName"
              name="ComplianceSubCategoryName" placeholder="Enter Sub Category Name" class="form-control" required>
          </mat-form-field>
        </div>
        <div class="action-btn">
          <button type="submit" class="btn btn-primary" [disabled]="myForm.form.invalid"
            (click)="dialogRef.close(InputData)">
            {{InputData.ComplianceId ? 'Update' : 'Save'}}
          </button>
          <button type="button" class="btn btn-secondary" (click)="dialogRef.close(false)">
            Cancel
          </button>
        </div>
      </form>
    </div>

    <!-- Compliance Department -->
    <div *ngIf="action == 'AddComplianceDepartment' || action == 'EditComplianceDepartment'">
      <form class="row" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Department Name</mat-label>
            <input matInput type="text" required [(ngModel)]="InputData.DepartmentName" name="DepartmentName"
              placeholder="Enter Department Name" class="form-control" required>
          </mat-form-field>
        </div>
        <div class="action-btn">
          <button type="submit" class="btn btn-primary" [disabled]="myForm.form.invalid"
            (click)="dialogRef.close(InputData)">
            {{InputData.DepartmentId ? 'Update' : 'Save'}}
          </button>
          <button type="button" class="btn btn-secondary" (click)="dialogRef.close(false)">
            Cancel
          </button>
        </div>
      </form>
    </div>


    <!-- Compliance Name -->
    <div *ngIf="action == 'AddCompliance' || action == 'EditCompliance'">
      <form class="row" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate autocomplete="off">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Compliance Name</mat-label>
            <input matInput type="text" required [(ngModel)]="InputData.ComplianceName" name="ComplianceName"
              placeholder="Enter Compliance Name" class="form-control" required>
          </mat-form-field>
        </div>
        <div class="action-btn">
          <button type="submit" class="btn btn-primary" [disabled]="myForm.form.invalid"
            (click)="dialogRef.close(InputData)">
            {{InputData.ComplianceNameId ? 'Update' : 'Save'}}
          </button>
          <button type="button" class="btn btn-secondary" (click)="dialogRef.close(false)">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
