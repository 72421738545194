import { T } from '@angular/cdk/keycodes';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalComponent } from 'app/main/core/common/common-modal/common-modal.component';
import { planworkConfig } from 'app/planwork.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DropdownService {
  company: any;
  apiUrl: any;
  baseUrl: any;
  customerSegmentUrl: any;
  IndustryTypeUrl: any;
  OrginisationTypeUrl: any;
  PaymentModeUrl: any;
  EntitlementCodeDropDownListUrl: any;
  ReferenceModeUrl: any;
  CurrencyUrl: any;
  NumberSystemUrl: any;
  ClientListUrl: any;
  VendorFacilityDropDownListUrl: any;
  CountryDropDownUrl: any;
  ClientDepartmentUrl: any;
  TerritoryUrl: any;
  DurationUnitUrl: any;
  DepartmentDropDownListUrl: any;
  KeySkillListUrl: any;
  DesignationUrl: any;
  public httpOptions: any;
  KeySkillDropDownUrl: any;
  LeadCategoryUrl: any;
  LeadCategoryUrllead: any;
  KeyFeatureUrl: any;
  LeadTypeUrl: any;
  PriorityListUrl: any;
  QualificationUrl: any;
  DocumentTypeUrl: any;
  SkillCategoryUrl: any;
  AccountManagerUrl: any;
  ServiceManagerUrl: any;
  DeliveryManagerUrl: any;
  ServiceAuditorUrl: any;
  ProfitCenterUrl: any;
  TaxTypeUrl: any;
  ModeOfPOUrl: any;
  StateUrl: any;
  CityUrl: any;
  DurationUnitUrl2: any;
  CompanyBillingUrl: any;
  PORaisedByUrl: any;
  EmployeeDropDownUrl: any;
  PaymentMilestoneUnitUrl: any;
  PaymentTypeUrl: any;
  BankAccountTypeUrl: any;
  BankListUrl: any;
  ModeListUrl: any;
  YearListUrl: any;
  YearDropDownListUrl: any;
  ShiftDropDownListUrl: any;
  NationalityDropDownListUrl: any;
  OccupationDropDownListUrl: string;
  EmployeeCatDropDownListUrl: string;
  MonthDropDownListUrl: string;
  CompanyDesignationListUrl: string;
  LevelListUrl: string;
  CadreDropDownListUrl: string;
  GetCompanyShiftListUrl: string;
  GetCompanyDepartmentList: string;
  GetCompanyDepartmentListUrl: string;
  EmployeeCostCenterDropDownListsUrl: string;
  branchListDropDownListsUrl: string;
  IndustryTypeDropDownListUrl: string;
  SourceDataDropDownListUrl: string;
  CampaignGroupDropDownListUrl: string;
  BackgroundInfoDropDownListUrl: string;
  CompanyBillingListUrl: string;
  VendorListUrl: string;
  ReportingAuthorityDropDownListUrl: string;
  UserRoleDropDownListUrl: string;
  SBHUrlDropDownListUrl: string;
  SBHUrlHeadListUrl: any;
  exceptionText = 'There are some exception error, Please contact to support team.';
  dialogRef: any;
  OtherAvtivityDropDownListUrl: string;
  OtherActivityDropDownListrootUrl: any;
  TeamMemberDropDownListUrl: string;
  PaymentmodeUrl: any
  VendorServiceProviderDropDownList: any;
  VendorSupplierDropDownList: any;
  VendorComplianceDropDownList: any;
  AssetClassDropDownList: any;
  AssetItemComDropDownList: any;
  AssetCategoryDropDownList: any
  AssetItemDropDownList: any
  ExpenseEntitlementsCodeDropDownList: any
  ExpMonthlyCategoryDropDownList: any
  supportCatergoryListURL: any;
  supportSubCatergoryListURL: any;
  AssetBlockDropDownList: string;
  AssetFloorDropDownList: string;
  AssetattributeDropDownList: string;
  AssetSectionDropDownList: string;
  Accomdationurl: string;
  ExpenseTypenurl: any;
  PreferredLocationListURL: string;
  constructor(
    private http: HttpClient, public spinner: NgxSpinnerService, public dialog: MatDialog
  ) {
    this.company = JSON.parse(localStorage.getItem('Company'));
    this.httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        })
    }
    this.apiUrl = planworkConfig;
    console.log(this.apiUrl);

    this.customerSegmentUrl = this.apiUrl.baseUrl + this.apiUrl.ClientBaseUrl + 'Lead/CustomerSegmentDropDownLists';
    this.IndustryTypeUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/IndustryTypeDropDownList';
    this.OrginisationTypeUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/OrganisationTypeDropDownList';
    this.PaymentModeUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/PaymentModeList';
    this.ReferenceModeUrl = this.apiUrl.baseUrl + this.apiUrl.ClientBaseUrl + 'Client/ReferenceModeDropDownList';
    this.CurrencyUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/CurrencyDropDownLists';
    this.NumberSystemUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/NumberSystemDropDownList';
    this.ClientListUrl = this.apiUrl.baseUrl + this.apiUrl.ClientBaseUrl + 'Lead/CommonClientDropDownList';
    this.ClientDepartmentUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Campaign/GetClientDepartmentDropdownList';
    this.TerritoryUrl = this.apiUrl.baseUrl + this.apiUrl.CampaignBaseUrl + 'campaigns/TerritoryDropDownList';

    this.CountryDropDownUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/CountryDropDownList';
    this.KeySkillDropDownUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Lead/KeySkillDropDownLists';
    this.DurationUnitUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Campaign/DurationUnitDropDownLists';
    this.DepartmentDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'CompanyDetail/SkillCategoryDepartmentDropDownList';
    this.KeySkillListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/KeySkillDropDownList';
    this.DesignationUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/DesignationDropDownLists';
    this.LeadCategoryUrl = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl + 'Service/ServiceCategoryDropDownList';
    this.LeadCategoryUrllead = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl + 'Service/LeadCategoryDropDownList';
    this.KeyFeatureUrl = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'CampaignEnquiry/KeyFeatureDropDownLists?BusinessProcessId=';
    this.LeadTypeUrl = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl + 'Service/LeadTypeDropDownList';
    this.ServiceManagerUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/ServiceManagerList?ClientId=';
    this.DeliveryManagerUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/DeliveryManagerList?ClientId=';
    this.ServiceAuditorUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/ServiceAuditorList?ClientId=';
    this.ProfitCenterUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/ProfitCenterList?ClientId=';
    this.ModeOfPOUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/ModeOfPOList?ClientId=';
    this.PriorityListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Lead/PriorityStatusDropDownLists';
    this.QualificationUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/QualificationDropDownList';
    this.DocumentTypeUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Lead/DocumentTypeDropDownList';
    this.SkillCategoryUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Lead/SkillCategoryDropDownLists';
    this.AccountManagerUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/AccountManagerList';
    this.StateUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/StateDropDown?CountryId=';
    this.CityUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/CityDropDown?StateId=';
    this.TaxTypeUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/TaxTypeDropDownList';
    // this.TaxTypeUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/TaxTypeList';
    this.CompanyBillingUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/CompanyBillingListForService?ClientId=';
    this.PaymentMilestoneUnitUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/PaymentMilestoneUnitList';
    this.PORaisedByUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/PORaisedByList';
    this.EmployeeDropDownUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Campaign/EmployeeDropDownList';
    this.DurationUnitUrl2 = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/DurationUnitList';
    this.PaymentTypeUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Lead/PaymentTypeList';
    this.BankAccountTypeUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/BankAccountTypeList';
    this.BankListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/BankList';
    this.ModeListUrl = this.apiUrl.baseUrl + this.apiUrl.ExpenseBaseUrl + 'Expense/ModeofConveyanceDropDownList';
    this.YearListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/YearList';
    this.YearDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/YearDropdownList';
    this.ShiftDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'CompanyDetail/GetCompanyShiftLists';
    this.NationalityDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + "Configuration/NationalityDropDownList";
    this.OccupationDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/OccupationDropDownList";
    this.EmployeeCatDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/EmployeeCategoryDropDownList";
    this.MonthDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/MonthDropDownList";
    this.CompanyDesignationListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "CompanyDetail/CompanyDesignationList";
    this.LevelListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/LevelListNew";
    this.BackgroundInfoDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/BackgroundInfoDropDownList";
    this.CadreDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/CadreDropDownList";
    this.GetCompanyShiftListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "CompanyDetail/GetCompanyShiftLists";
    this.GetCompanyDepartmentListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "CompanyDetail/GetCompanyDepartmentList";
    this.EmployeeCostCenterDropDownListsUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "CompanyDetail/EmployeeCostCenterDropDownLists";
    this.branchListDropDownListsUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "CompanyDetail/GetCompanyBranchDropDownList";
    this.IndustryTypeDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + "Configuration/IndustryTypeDropDownList";
    this.SourceDataDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.CampaignBaseUrl + "Configuration/SourceDataDropDownList";
    this.CampaignGroupDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.CampaignBaseUrl + "Configuration/CampaignGroupDropDownList";
    this.CompanyBillingListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "Service/CompanyBillingList";
    this.VendorListUrl = this.apiUrl.baseUrl + this.apiUrl.VendorBaseUrl + "Vendor/GetVendorDropDownList";
    this.ReportingAuthorityDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/ReportingAuthorityDropDownList";
    this.UserRoleDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/UserRoleDropDownListNew";
    this.SBHUrlDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "CompanyDetail/ActiveCostCenterSBHList ";
    this.OtherAvtivityDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.TaskBaseUrl + "TaskAssignments/OtherActivityDropDownList";
    this.TeamMemberDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.TaskBaseUrl + "Task/ProjectManagerTeamMember";
    this.OtherActivityDropDownListrootUrl = this.apiUrl.baseUrl + this.apiUrl.TaskBaseUrl + "OtherActivity/OtherActivityDropDownList";
    this.SBHUrlHeadListUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + "CompanyDetail/SBUHeadDropDownList";
    this.EntitlementCodeDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + "Employee/EntitlementCodeDropDownList";
    this.PaymentmodeUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/PaymentModeDropDownList';
    this.VendorServiceProviderDropDownList = this.apiUrl.baseUrl + this.apiUrl.VendorBaseUrl + 'Vendor/VendorServiceCategoryDropDownList';
    this.VendorSupplierDropDownList = this.apiUrl.baseUrl + this.apiUrl.VendorBaseUrl + 'Vendor/VendorSupplierCategoryDropDownList';
    this.VendorComplianceDropDownList = this.apiUrl.baseUrl + this.apiUrl.VendorBaseUrl + 'Vendor/VendorComplianceDropDownList'
    this.VendorFacilityDropDownListUrl = this.apiUrl.baseUrl + this.apiUrl.VendorBaseUrl + 'Vendor/VendorFacilityDropDownList';
    this.AssetClassDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetClassDropDownList';
    this.AssetItemComDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetItemComponentDropDownList';
    this.AssetattributeDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetAttributeDropDownList?itemid=';

    this.AssetCategoryDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetCategoryDropDownList';
    this.AssetItemDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetItemDropDownList';
    this.AssetBlockDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetBlockDropDownList?LocationId=';
    this.AssetFloorDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetFloorDropDownList?BlockId=';
    this.AssetSectionDropDownList = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/GetAssetSectionDropDownList?FloorId=';
    this.ExpenseEntitlementsCodeDropDownList = this.apiUrl.baseUrl + this.apiUrl.ExpenseBaseUrl + 'Expense/ExpenseEntitlementsCodeList';
    this.ExpMonthlyCategoryDropDownList = this.apiUrl.baseUrl + this.apiUrl.ExpenseBaseUrl + 'Expense/MonthlyCategoryDropDownList';
    this.supportCatergoryListURL = this.apiUrl.baseUrl + this.apiUrl.SupportBaseUrl + 'Master/CategoryDropDownList';
    this.Accomdationurl = this.apiUrl.baseUrl + this.apiUrl.ExpenseBaseUrl + 'Expense/ExpenseAccomodationDropDownList';
    this.ExpenseTypenurl = this.apiUrl.baseUrl + this.apiUrl.ExpenseBaseUrl + 'Expense/ExpenseTypeDropDownList';
    this.supportSubCatergoryListURL = this.apiUrl.baseUrl + this.apiUrl.SupportBaseUrl + 'Master/SubcategoryDropDownList?CategoryId=';
    this.PreferredLocationListURL = this.apiUrl.baseUrl + this.apiUrl.CvBaseUrl + 'ResumeEntries/GetPreferredLocation';
  }


  GetAssetClassDropDownList(payload): Observable<any> {
    return this.http.post(this.AssetClassDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  GetAssetItemComDropDownList(payload): Observable<any> {
    return this.http.post(this.AssetItemComDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  AssetattributeDropDown(id, payload): Observable<any> {
    console.log(id);

    return this.http.post(this.AssetattributeDropDownList + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }

  GetAssetCategoryDropDownList(payload): Observable<any> {
    return this.http.post(this.AssetCategoryDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  GetAssetItemDropDownList(payload): Observable<any> {
    return this.http.post(this.AssetItemDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ExpenseEntitlementsCodeList(payload): Observable<any> {
    return this.http.post(this.ExpenseEntitlementsCodeDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  MonthlyCategoryDropDownList(payload): Observable<any> {
    return this.http.post(this.ExpMonthlyCategoryDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  paymentmode(payload): Observable<any> {
    return this.http.post(this.PaymentmodeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  VendorServiceProvider(payload): Observable<any> {
    return this.http.post(this.VendorServiceProviderDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  VendorSupplier(payload): Observable<any> {
    return this.http.post(this.VendorSupplierDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }

  VendorCompliance(payload): Observable<any> {
    return this.http.post(this.VendorComplianceDropDownList, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }

  postService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }







  //CustomerSegmentDropDownList
  customerSegmentDropDown(payload): Observable<any> {
    return this.http.post(this.customerSegmentUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }

  // OrginisationTypeDropDown
  OrginisationTypeDropDown(payload): Observable<any> {
    return this.http.post(this.OrginisationTypeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  PaymentModeDropDown(payload): Observable<any> {
    return this.http.post(this.PaymentModeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // IndustryTypeDropDown
  IndustryTypeDropDown(payload): Observable<any> {
    return this.http.post(this.IndustryTypeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // ReferenceModeDropDownList
  ReferenceModeDropDown(payload): Observable<any> {
    return this.http.post(this.ReferenceModeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // CurrencyDropDownList
  CurrencyDropDownList(payload): Observable<any> {
    return this.http.post(this.CurrencyUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // NumberSystemDropDownList
  NumberSystemDropDownList(payload): Observable<any> {
    return this.http.post(this.NumberSystemUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // ClientListDropDownList
  ClientListDropDownList(payload): Observable<any> {
    return this.http.post(this.ClientListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // GetClientDepartmentList
  GetClientDepartmentList(payload): Observable<any> {
    return this.http.post(this.ClientDepartmentUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  GetTerritoryList(payload): Observable<any> {
    return this.http.post(this.TerritoryUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // CountryDropDownlist
  VendorFacilityDropDownList(payload): Observable<any> {
    return this.http.post(this.VendorFacilityDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // CountryDropDownlist
  CountryDropDownlist(payload): Observable<any> {
    return this.http.post(this.CountryDropDownUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // KeySkillDropDownList
  KeySkillDropDownList(payload): Observable<any> {
    return this.http.post(this.KeySkillDropDownUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // DurationUnitDropDownList
  DurationUnitDropDownList(payload): Observable<any> {
    return this.http.post(this.DurationUnitUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));

  }
  // DepartmentDropDownList
  DepartmentDropDownList(payload): Observable<any> {
    return this.http.post(this.DepartmentDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // KeySkillList
  KeySkillList(payload): Observable<any> {
    return this.http.post(this.KeySkillListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  // DesignationList
  DesignationList(payload): Observable<any> {
    return this.http.post(this.DesignationUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //LeadCategoryUrl
  LeadCategoryDropDownList(id, payload): Observable<any> {
    return this.http.post(this.LeadCategoryUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //LeadCategoryUrl
  LeadCategoryDropDownListlead(id, payload): Observable<any> {
    return this.http.post(this.LeadCategoryUrllead, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //KeyFeatureUrl
  KeyFeatureDropDownList(id, payload): Observable<any> {
    return this.http.post(this.KeyFeatureUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //LeadTypeUrl
  LeadTypeDropDownList(id, Lid, payload): Observable<any> {
    return this.http.post(this.LeadTypeUrl + '?LeadCategoryId=' + Lid, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //PriorityStatusList
  PriorityStatusList(payload): Observable<any> {
    return this.http.post(this.PriorityListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //QualificationList
  QualificationList(payload): Observable<any> {
    return this.http.post(this.QualificationUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //DocumentTypeList
  DocumentTypeList(payload): Observable<any> {
    return this.http.post(this.DocumentTypeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //SkillCategoryList
  SkillCategoryList(payload): Observable<any> {
    return this.http.post(this.SkillCategoryUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  AccountManagerDropDown(payload): Observable<any> {
    return this.http.post(this.AccountManagerUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ServiceManagerDropDown(id, payload): Observable<any> {
    return this.http.post(this.ServiceManagerUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  DeliveryManagerDropDown(id, payload): Observable<any> {
    return this.http.post(this.DeliveryManagerUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ServiceAuditorDropDown(id, payload): Observable<any> {
    return this.http.post(this.ServiceAuditorUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ProfitCenterDropDown(id, payload): Observable<any> {
    return this.http.post(this.ProfitCenterUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ModeOfPODropDown(id, payload): Observable<any> {
    return this.http.post(this.ModeOfPOUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  StateDropDown(id, payload): Observable<any> {
    return this.http.post(this.StateUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  CityDropDown(id, payload): Observable<any> {
    return this.http.post(this.CityUrl + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  TaxTypeDropDown(payload): Observable<any> {
    return this.http.post(this.TaxTypeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  CompanyBillingDropDown(id, payload): Observable<any> {
    return this.http.post(this.CompanyBillingUrl + id + '&Companyid=' + this.company?.CompanyId, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  DurationUnitDropDown(payload): Observable<any> {
    return this.http.post(this.DurationUnitUrl2, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  PaymentMilestoneUnitDropDown(payload): Observable<any> {
    return this.http.post(this.PaymentMilestoneUnitUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  PORaisedByDropDown(payload): Observable<any> {
    return this.http.post(this.PORaisedByUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  EmployeeDropDown(payload): Observable<any> {
    return this.http.post(this.EmployeeDropDownUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  PaymentTypeDropDown(payload): Observable<any> {
    return this.http.post(this.PaymentTypeUrl, payload, this.httpOptions)

      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));

  }
  BankAccountTypeList(payload): Observable<any> {
    return this.http.post(this.BankAccountTypeUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  BankListfunc(payload): Observable<any> {
    return this.http.post(this.BankListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ModeListfunc(payload): Observable<any> {
    return this.http.post(this.ModeListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  YearList(payload): Observable<any> {
    return this.http.post(this.YearListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  YearDropDownList(payload): Observable<any> {
    return this.http.post(this.YearDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  supportCatergorylist(payload): Observable<any> {
    return this.http.post(this.supportCatergoryListURL, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  supportSubCatergoryList(id, payload): Observable<any> {
    return this.http.post(this.supportSubCatergoryListURL + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ShiftDropDownList(payload): Observable<any> {
    return this.http.post(this.ShiftDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  //Nationality Dropdownlist
  NationalityDropDownList(payload): Observable<any> {
    return this.http.post(this.NationalityDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  OccupationDropDownList(payload): Observable<any> {
    return this.http.post(this.OccupationDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  EmployeecatDropDownList(payload): Observable<any> {
    return this.http.post(this.EmployeeCatDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  MonthDropDownList(payload): Observable<any> {
    return this.http.post(this.MonthDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  CompanyDesignationList(payload): Observable<any> {
    return this.http.post(this.CompanyDesignationListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  LevelList(payload): Observable<any> {
    return this.http.post(this.LevelListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  cadreddList(payload): Observable<any> {
    return this.http.post(this.CadreDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  GetCompanyShiftList(payload): Observable<any> {
    return this.http.post(this.GetCompanyShiftListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  GetCompanyDepartmentListdd(payload): Observable<any> {
    return this.http.post(this.GetCompanyDepartmentListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  branchListDropDownList(payload): Observable<any> {
    return this.http.post(this.branchListDropDownListsUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  EmployeeCostCenterDropDownList(payload): Observable<any> {
    return this.http.post(this.EmployeeCostCenterDropDownListsUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  IndustryTypeDropDownList(payload): Observable<any> {
    return this.http.post(this.IndustryTypeDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  SourceDataDropDownList(payload): Observable<any> {
    return this.http.post(this.SourceDataDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  CampaignGroupDropDownList(payload): Observable<any> {
    return this.http.post(this.CampaignGroupDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  BackgroundInfoDropDownList(payload): Observable<any> {
    return this.http.post(this.BackgroundInfoDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  VendorList(payload): Observable<any> {
    return this.http.post(this.VendorListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  CompanyBillingList(payload): Observable<any> {
    return this.http.post(this.CompanyBillingListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  assetblock(id, payload): Observable<any> {
    return this.http.post(this.AssetBlockDropDownList + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  assetfloor(id, payload): Observable<any> {
    return this.http.post(this.AssetFloorDropDownList + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  assetsection(id, payload): Observable<any> {
    return this.http.post(this.AssetSectionDropDownList + id, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ReportingAuthorityDropDownList(payload): Observable<any> {
    return this.http.post(this.ReportingAuthorityDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  UserRoleDropDownList(payload): Observable<any> {
    return this.http.post(this.UserRoleDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  SBHList(payload): Observable<any> {
    return this.http.post(this.SBHUrlHeadListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  SBHDropDownList(payload): Observable<any> {
    return this.http.post(this.SBHUrlDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }

  handleError(error: HttpErrorResponse) {
    this.spinner.hide();
    this.dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: 'delete', statusText: this.exceptionText }
    });
  }
  ShowError() {
    this.spinner.hide();
    this.dialogRef = this.dialog.open(CommonModalComponent, {
      width: '300px',
      disableClose: true,
      autoFocus: false,
      panelClass: 'statusClass',
      data: { action: 'delete', statusText: this.exceptionText }
    });
  }

  OtherActivityDropDownList(payload): Observable<any> {
    return this.http.post(this.OtherAvtivityDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  TeamMemberDropDownList(payload): Observable<any> {
    return this.http.post(this.TeamMemberDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }

  OtherActivityDropDownrootList(payload): Observable<any> {
    return this.http.post(this.OtherActivityDropDownListrootUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  EntitlementCodeDropDownList(payload): Observable<any> {
    return this.http.post(this.EntitlementCodeDropDownListUrl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  AccomdationDropDown(payload): Observable<any> {
    return this.http.post(this.Accomdationurl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  ExpenseDropDown(payload): Observable<any> {
    return this.http.post(this.ExpenseTypenurl, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
  GetPreferredLocationDropDown(payload): Observable<any> {
    return this.http.post(this.PreferredLocationListURL, payload, this.httpOptions)
      .pipe(catchError((e: any) => {
        this.ShowError()
        return throwError(this.exceptionText);
      }));
  }
}
