import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServiceService } from '../../masterservice/service.service';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.scss']
})
export class GlobalModalComponent implements OnInit {
  modalTitle: any;
  action: any;
  InputData: any;
  categorylist: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<GlobalModalComponent>,
    public dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private coreservice: ServiceService
  ) {
    this.modalTitle = data.modalTitle || '';
    this.action = data.action || '';
    this.InputData = data.data || {};
  }


  closeModal(data:any = false){
    this.dialogRef.close(data);
  }

  categorydropdown(): void {
    this.spinner.show()
    this.coreservice.compliencesdropdown({ ISGoverment: false,IsAll:true }, 'CompliancesCategoryDropDownList').subscribe(res => {
      this.categorylist = res.body
      this.spinner.hide()
    })
  }

  ngOnInit(): void {
    if (this.action == 'AddComplianceSubcategory' || this.action == 'EditComplianceSubcategory') {
      this.categorydropdown();
    }
  }
}

