import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { planworkConfig } from 'app/planwork.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import axios from 'axios';

import { NgxSpinnerService } from 'ngx-spinner';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CommonModalComponent } from '../common/common-modal/common-modal.component';
@Injectable({
  providedIn: 'root'
})

export class ServiceService {
  config: any;
  SpinnerTimeout = 500;
  ClientIP: any;
  IPAddress: any;
  apiUrl: any;
  private baseUrl: any;
  private baseloginUrl: any;
  private baseKeyUrl: any;
  private baseUrlClient: any;
  private baseUrlEmployee: any;
  private userRole: any;
  private baseDashboardUrl: any;
  private baseUrlService: any;
  private baseLeadForFC: any;
  private baseServiceURL: any;
  private baseBillingURL: any;
  private baseInvoiceURL: any;
  private baseEmployeeURL: any;
  private globalBaseUrl: any;
  private EmployeeBaseUrl: any;
  private EmpTaxInvoiceBaseUrl: any;
  private ClientBaseUrl: any;
  private companyBaseUrl: any;
  private BillingBaseUrl: any;
  private companyCoreUrl: any;
  private companyCoreUrlservice: any;
  private LeadBaseUrl: any;
  private CampaignBaseUrl: any;
  private baseUrlAllocate: any;
  private baseUrlStaffing: any;
  private baseExpenseUrl: any;
  private baseRembersmentUrl: any;
  private basepurchaseUrl: any;
  private supportCatergoryURL: any;
  private PettyCashBaseUrl: any;
  private AdvanceBaseUrl : any;
  private ConsumableBaseUrl: any;
  private ConsumableBaseUrl1: any;
  private ConsumableBaseUrl2: any;
  private AssetBaseUrl: any;
  private RepairBaseUrl: any;
  private PaymentCompliancesBaseUrl: any;
  private CompliancesRequisitionsBaseUrl: any;
  private PaymentBaseUrl: any;
  private RMrfqBaseUrl: any;
  private RMGRNBaseUrl: any;
  dialogRef: any;
  exceptionText = 'There are some exception error, Please contact to support team.';
  Otheractivity: string;
  assetCoreSerivceUrl: any;
  dmsDocumentUrl: any;
  baseassetpurchaseGRNUrl: any;
  requsitionUrl: string;
  DepositUrl: string;
  baseassetpurchaseUrl: any;
  baseassetpurchaseUrl2: any;
  baseassetpurchaseUrl3: any;
  baseassetpurchaserequestUrl: string;
  baseassetpurchasePOUrl: string;
  baseInvitedRfqUrl: string
  baseUrlglobal: string;
  complincesurl: any;
  cvsurl:any;
  complincesdropdownurl: string;


  constructor(

    private http: HttpClient, private spinner: NgxSpinnerService,

    public dialog: MatDialog
  ) {
    // this.http.get<any>('https://jsonip.com')
    // .subscribe(data => {
    //   const serverIp = data.ip;
    //   const privateIP = this.ClientIP;
    //   const IP = serverIp + ':' + privateIP;
    //   this.IPAddress = IP;
    // });
    this.apiUrl = planworkConfig;

    if (this.apiUrl.baseUrl == "https://sdmapibeta.vertexplus.com/" || this.apiUrl.baseUrl == "http://sdmapibeta.vertexplus.com/") {
      this.dmsDocumentUrl = "https://dmsapidev.vertexplus.com/DMS/api/"
    }
    else {

      this.dmsDocumentUrl = `${this.apiUrl?.baseUrl}DMS/api/`;
    }
    this.baseloginUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl;
    this.baseDashboardUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Dashboard/';
    this.baseExpenseUrl = this.apiUrl.baseUrl + this.apiUrl.ExpenseBaseUrl + 'Expense/';
    this.baseUrl = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/';
    this.baseUrlglobal = this.apiUrl.baseUrl + this.apiUrl.GlobalBaseUrl + 'Configuration/';
    this.baseRembersmentUrl = this.apiUrl.baseUrl + this.apiUrl.PettyCashBaseUrl + 'Reimbursement/';
    this.basepurchaseUrl = this.apiUrl.baseUrl + 'Asset/api/AssetPurchaseRequest/';
    this.baseassetpurchaseUrl = this.apiUrl.baseUrl + 'Purchases/api/Rfq/';
    this.baseassetpurchaseUrl2 = this.apiUrl.baseUrl + 'Purchases/api/RMRfq/';
    this.baseassetpurchaseUrl3 = this.apiUrl.baseUrl + 'Purchases/api/WorkOrder/';
    this.baseassetpurchaserequestUrl = this.apiUrl.baseUrl + 'Purchases/api/PurchaseRequest/';
    this.baseInvitedRfqUrl = this.apiUrl.baseUrl + 'Purchases/api/InvitedRfq/';
    this.complincesurl = this.apiUrl.baseUrl + 'CompliancesRequisitions/api/CompliancesRequisitions/';
    this.cvsurl = this.apiUrl.baseUrl + 'CV/api/ResumeEntries/';
    this.complincesdropdownurl = this.apiUrl.baseUrl + 'Compliances/api/Compliances/';
    this.baseassetpurchasePOUrl = this.apiUrl.baseUrl + 'Purchases/api/PO/';
    this.baseassetpurchaseGRNUrl = this.apiUrl.baseUrl + 'Purchases/api/GRN/';
    this.baseUrlClient = this.apiUrl.baseUrl + this.apiUrl.ClientBaseUrl + 'Client/';
    this.baseUrlService = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl + 'Service/';
    //this.baseUrlEmployee = this.apiUrl.baseUrl + 'core/api/Employee/';
    this.baseUrlEmployee = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/';
    this.EmpTaxInvoiceBaseUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'EmployeeTaxInvoice/';

    this.userRole = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'RolePermission/';
    this.baseKeyUrl = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'Configuration/';
    this.baseLeadForFC = this.apiUrl.baseUrl + this.apiUrl.LeadBaseUrl + 'LeadForFC/';
    this.baseServiceURL = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl + 'Service/';
    this.baseBillingURL = this.apiUrl.baseUrl + this.apiUrl.BillingBaseUrl + 'ServiceBilling/';
    this.baseInvoiceURL = this.apiUrl.baseUrl + this.apiUrl.BillingBaseUrl + 'Invoices/';
    this.requsitionUrl = this.apiUrl.baseUrl + 'Asset/api/AssetRequisitions/';
    this.DepositUrl = this.apiUrl.baseUrl + 'Asset/api/Deposit/';

    this.baseEmployeeURL = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/';
    this.baseEmployeeURL = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/';
    // shivram new service params --------
    this.globalBaseUrl = this.apiUrl.GlobalBaseUrl + 'Configuration/';
    this.Otheractivity = this.apiUrl.TaskBaseUrl + 'OtherActivity/'
    this.EmployeeBaseUrl = this.apiUrl.EmployeeBaseUrl + 'Employee/';
    this.ClientBaseUrl = this.apiUrl.ClientBaseUrl + 'Client/';
    this.companyBaseUrl = this.apiUrl.CompanyBaseUrl + 'CompanyDetail/';
    this.companyCoreUrl = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'CompanyDetail/';

    this.companyCoreUrlservice = this.apiUrl.baseUrl + this.apiUrl.CompanyBaseUrl + 'Service/';
    this.LeadBaseUrl = this.apiUrl.LeadBaseUrl + 'Configuration/';
    this.BillingBaseUrl = this.apiUrl.BillingBaseUrl + 'Configuration/';
    this.CampaignBaseUrl = this.apiUrl.CampaignBaseUrl + 'Configuration/';
    this.baseUrlAllocate = this.apiUrl.baseUrl + this.apiUrl.TaskBaseUrl + 'Task/'
    this.baseUrlStaffing = this.apiUrl.baseUrl + this.apiUrl.TaskBaseUrl + 'StaffingTask/';
    this.supportCatergoryURL = this.apiUrl.baseUrl + this.apiUrl.SupportBaseUrl + 'Master/';
    this.PettyCashBaseUrl = this.apiUrl.baseUrl + this.apiUrl.PettyCashBaseUrl + 'PettyCash/';
    this.AdvanceBaseUrl = this.apiUrl.baseUrl + this.apiUrl.PettyCashBaseUrl + 'AdvancePayment/';
    this.PaymentBaseUrl = this.apiUrl.baseUrl + this.apiUrl.PettyCashBaseUrl + 'Asset/';
    this.ConsumableBaseUrl2 = this.apiUrl.baseUrl + this.apiUrl.ConsumableBaseUrl + 'Consumable/';
    this.ConsumableBaseUrl1 = this.apiUrl.baseUrl + this.apiUrl.ConsumableBaseUrl + 'ConsumablePurchaseRequest/';
    this.ConsumableBaseUrl = this.apiUrl.baseUrl + this.apiUrl.ConsumableBaseUrl + 'ConsumableRequisitions/';
    this.AssetBaseUrl = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'AssetRegistration/';
    this.PaymentCompliancesBaseUrl = this.apiUrl.baseUrl + this.apiUrl.PettyCashBaseUrl + 'PaymentCompliances/';
    this.RepairBaseUrl = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'RepairAndMaintenance/';
    this.RMrfqBaseUrl = this.apiUrl.baseUrl + this.apiUrl.PurchaseBaseUrl + 'RMRfq/';
    this.RMGRNBaseUrl = this.apiUrl.baseUrl + this.apiUrl.PurchaseBaseUrl + 'RMGRN/';
    // shivram new service params --------
    this.CompliancesRequisitionsBaseUrl = this.apiUrl.baseUrl + `CompliancesRequisitions/api/CompliancesRequisitions/`;



  }

  async asyncPostService(saveData: any, currentUrl: any, baseUrlType: any) {
    this.spinner.show();
    let finalUrl = ''
console.log(baseUrlType);

    switch (baseUrlType) {
      case 'DocPrintCategories':
        finalUrl = this.apiUrl.baseUrl + 'DocPrint/api/DocPrintCategories/'
        break;
      case 'CompliancesCore':
        finalUrl = this.apiUrl.baseUrl + 'Compliances/api/Compliances/'
        break;
      case 'DocPrintType':
        finalUrl = this.apiUrl.baseUrl + 'DocPrint/api/DocPrintType/'
        break;
      case 'DocPrintType':
        finalUrl = this.apiUrl.baseUrl + 'DocPrint/api/DocPrintType/'
        break;
      case 'GlobalCore':
        finalUrl = this.apiUrl.baseUrl + this.globalBaseUrl
        break;
      case 'OtherActivity':
        finalUrl = this.apiUrl.baseUrl + this.Otheractivity
        break;
      case 'EmployeeCore':
        finalUrl = this.apiUrl.baseUrl + this.EmployeeBaseUrl
        break;
      case 'ClientCore':
        finalUrl = this.apiUrl.baseUrl + this.ClientBaseUrl
        break;
      case 'CompanyCore':
        finalUrl = this.apiUrl.baseUrl + this.companyBaseUrl
        break;
      case 'Lead':
        finalUrl = this.apiUrl.baseUrl + this.LeadBaseUrl
        break;
      case 'Billing':
        finalUrl = this.apiUrl.baseUrl + this.BillingBaseUrl
        break;
      case 'Campaign':
        finalUrl = this.apiUrl.baseUrl + this.CampaignBaseUrl
        break;
      case 'Asset':
        finalUrl = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'Asset/'
        break;
      case 'Assetregistration':
        finalUrl = this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl + 'AssetRegistration/'
        break;
      case 'Expense':
        finalUrl = this.apiUrl.baseUrl + this.apiUrl.ExpenseBaseUrl + 'Expense/'
        break;
      case 'Consumable':
        finalUrl = this.apiUrl.baseUrl + this.apiUrl.ConsumableBaseUrl + 'Consumable/'
        break;
      case 'EmployeeCore':
        finalUrl = this.apiUrl.baseUrl + this.apiUrl.EmployeeBaseUrl + 'Employee/'
        break;
      case 'ServiceCore':
        finalUrl = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl + 'Service/'
        break;
      case 'Asset':
        finalUrl = this.apiUrl.baseUrl + this.apiUrl.ServiceBaseUrl + 'Service/'
        break;

    }
    finalUrl = finalUrl + currentUrl



    let response = { message: '' };
    try {
      response = await axios.post(finalUrl, saveData, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }

  async subscriber() {
    //return await axios.get('https://jsonip.com')
  }
  async loginService(saveData: any, currentUrl: any) {
    this.spinner.show();
    const subscriber = await this.subscriber()
    // && subscriber.data.ip
    //if () {
    const response = await axios.post(this.baseloginUrl + currentUrl, saveData);
    // Object.assign(response);
    this.spinner.hide();
    return response
    //}

  }
  ComplianceService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.PaymentCompliancesBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  CompliancesRequisitionsService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.CompliancesRequisitionsBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  RmrfqService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.RMrfqBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  RmgrnService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.RMGRNBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  AssetService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.AssetBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  RepairService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.RepairBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  ExpenseService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseExpenseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  RembersmentService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseRembersmentUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  assetPurchaseRequest(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.basepurchaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  PurchaseRequest(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaserequestUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  InvitedRfq(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseInvitedRfqUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  compliences(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.complincesurl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  cv(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.cvsurl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  compliencesdropdown(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.complincesdropdownurl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  PurchaseRequestPO(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchasePOUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  PurchaseRequestGrn(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaseGRNUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  vendorPurchaseRequest(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  vendorPurchaseRequest2(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaseUrl2 + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  vendorPurchaseRequest3(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseassetpurchaseUrl3 + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  AdvancePayment(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.AdvanceBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  Payment(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.PaymentBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  pettycash(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.PettyCashBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  supportDropDown(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.supportCatergoryURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  loginServicetoken(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseloginUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  LogoutService(saveData: any, currentUrl: any): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.baseloginUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  DashboardService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseDashboardUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  globalcore(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlglobal + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  URLCommon(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  ServiceService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseServiceURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  dms(saveData: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post('http://dmsapidev.vertexplus.com/api/Login/Token', saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  PostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseServiceURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }
   PostEmployeeCore(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.apiUrl.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }

  PostServiceAll(saveData: any, currentUrl: any): Observable<any> {

    return this.http.post(this.apiUrl.baseUrl + currentUrl, saveData,
      {
        observe: 'response'
      })
  }

  //Billing
  billingPostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseBillingURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }
  //Invoice
  invoicePostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseInvoiceURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }

  LeadForFCService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseLeadForFC + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  postService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  reuquisitonService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.requsitionUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  DepositService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.DepositUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  ConsumableService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.ConsumableBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  ConsumableService1(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.ConsumableBaseUrl1 + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  ConsumableService2(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.ConsumableBaseUrl2 + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }


  employeeCoreService(saveData: any, currentUrl: any): Observable<any> {



    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseEmployeeURL + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }


  companyCoreService(saveData: any, currentUrl: any): Observable<any> {

    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.companyCoreUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  assetCoreSerivce(saveData: any, currentUrl: any): Observable<any> {
    this.assetCoreSerivceUrl =this.apiUrl.baseUrl + this.apiUrl.AssetBaseUrl+"Asset/"
    // saveData.IpAddress = this.clientipService.IPAddress;
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.assetCoreSerivceUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  assetrfqCoreSerivce(saveData: any, currentUrl: any): Observable<any> {
    this.assetCoreSerivceUrl =this.apiUrl.baseUrl + this.apiUrl.PurchaseBaseUrl+"Rfq/"
    // saveData.IpAddress = this.clientipService.IPAddress;
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.assetCoreSerivceUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  companyCore(saveData: any, currentUrl: any): Observable<any> {

    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.companyCoreUrlservice + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }


  async companyCorepostService(saveData: any, currentUrl: any) {

    this.spinner.show();
    let response = { message: '' };
    try {
      response = await axios.post(this.companyCoreUrl + currentUrl, saveData,
        {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }


  async newpostService(saveData: any, currentUrl: any) {

    this.spinner.show();
    let response = { message: '' };
    try {
      response = await axios.post(this.baseUrl + currentUrl, saveData,
        {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }

  async newEditpostService(saveData: any, currentUrl: any) {
    this.spinner.show();
    let response = { message: '' };
    try {
      response = await axios.post(this.baseKeyUrl + currentUrl, saveData,
        {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });
    } catch (e) {
      response.message = e.message
    }
    this.spinner.hide();
    return response
  }
  async verynewpostService(saveData: any, currentUrl: any) {
    this.spinner.show();
    const response = await axios.post(this.baseUrlClient + currentUrl, saveData,
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
      });
    this.spinner.hide();
    return response
  }



  postKey(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseKeyUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  postClient(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlClient + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      );
  }

  postEmployee(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlEmployee + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  postEmpTaxInvoice(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.EmpTaxInvoiceBaseUrl + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  userRolePermission(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.userRole + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  Allocate(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlAllocate + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }
  staffingPostService(saveData: any, currentUrl: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(this.baseUrlStaffing + currentUrl, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      });
  }

  getDocument(saveData: any): Observable<any> {
    const currentToken = localStorage.getItem('token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + currentToken
    });
    return this.http.post(`${this.dmsDocumentUrl}PMS/DocumentGetByIdForSDM`, saveData,
      {
        headers: httpHeaders,
        observe: 'response'
      }).pipe(
        catchError(err => {
          this.spinner.hide();
          this.dialogRef = this.dialog.open(CommonModalComponent, {
            width: '300px',
            disableClose: true,
            autoFocus: false,
            panelClass: 'statusClass',
            data: { action: 'delete', statusText: this.exceptionText }
          });
          return throwError(err);
        })
      )
  }

}

